<template>
  <div class="legal">
    <h1 class="mb-5"><strong>Terms and Conditions</strong></h1>
    <h2>
      <strong>for &lsquo;'wearelocals'</strong>
    </h2>
    <p>
      <span
        >NB: 'wearelocals' is a trading name of
        <a
          class="employtec-link"
          target="_blank"
          href="https://www.employtec.com"
          >Employtec.</a
        ></span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>1. Introduction</strong></h2>
    <p>
      <span
        >These Website Standard Terms and Conditions written on this webpage
        shall manage your use of our website, &lsquo;'wearelocals'&rsquo;
        Website accessible at </span
      ><a href="http://www.wearelocals.co.uk"
        ><span>www.wearelocals.co.uk</span></a
      >
    </p>
    <p>
      <span
        >These Terms will be applied fully and affect your use of this Website.
        By using this Website, you agreed to accept all terms and conditions
        written in here. You must not use this Website if you disagree with any
        of these Website Standard Terms and Conditions.</span
      >
    </p>
    <p>
      <span
        >Age restrictions apply and are different depending on certain products
        and services. For more information please refer to the </span
      ><a
        href="https://www.gov.uk/government/publications/code-of-practice-age-restricted-products"
        ><span>government website</span></a
      ><span>
        or the department for business innovation and skills document on age
        restricted products and services </span
      ><a
        href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/299373/13-537-code-of-practice-age-restricted-products.pdf"
        ><span>HERE</span></a
      ><span>.</span>
    </p>
    <p>&nbsp;</p>
    <h2><strong>2. Intellectual property rights</strong></h2>
    <p>
      <span
        >Other than the content you own, under these Terms, &lsquo;'wearelocals'
        &amp; 'wearebs3'&rsquo; and/or its licensors (Employtec) own all the
        intellectual property rights and materials contained in this
        Website.</span
      >
    </p>
    <p>
      <span
        >You are granted limited license only for purposes of viewing the
        material contained on this Website.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>3. Restrictions</strong></h2>
    <p>
      <span>You are specifically restricted from all of the following:</span>
    </p>
    <p>&nbsp;</p>
    <ul>
      <li><span>publishing any Website material in any other media;</span></li>
      <li>
        <span
          >selling, sublicensing and/or otherwise commercializing any Website
          material;</span
        >
      </li>
      <li>
        <span>publicly performing and/or showing any Website material;</span>
      </li>
      <li>
        <span
          >using this Website in any way that is or may be damaging to this
          Website;</span
        >
      </li>
      <li>
        <span
          >using this Website in any way that impacts user access to this
          Website;</span
        >
      </li>
      <li>
        <span
          >using this Website contrary to applicable laws and regulations, or in
          any way may cause harm to the Website, or to any person or business
          entity;</span
        >
      </li>
      <li>
        <span
          >engaging in any data mining, data harvesting, data extracting or any
          other similar activity in relation to this Website;</span
        >
      </li>
      <li>
        <span
          >using this Website to engage in any advertising or marketing.</span
        >
      </li>
    </ul>
    <p>
      <span
        >Certain areas of this Website are restricted from being accessed by you
        and 'wearelocals' &amp; 'wearebs3' / Employtec may further restrict
        access by you to any areas of this Website, at any time, in absolute
        discretion. Any user ID and password you may have for this Website are
        confidential and you must maintain confidentiality as well.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>4. Your content</strong></h2>
    <p>
      <span
        >In these Website Standard Terms and Conditions, "Your Content" shall
        mean any audio, video, text, images or other material you choose to
        display on this Website. By displaying Your Content, you grant
        'wearelocals' &amp; 'wearebs3' / Employtec a non-exclusive, worldwide
        irrevocable, sub licensable license to use, reproduce, adapt, publish,
        translate and distribute it in any and all media.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>5. Your privacy</strong></h2>
    <p>
      <span
        >Please read our Privacy Policy. Your Content must be your own and must
        not be invading any third-party&rsquo;s rights. Employtec reserves the
        right to remove any of Your Content from this Website at any time
        without notice.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>6. No warranties</strong></h2>
    <p>
      <span
        >This Website is provided "as is," with all faults, and Employtec (the
        trading name of 'wearelocals' &amp; 'wearebs3'&rsquo; express no
        representations or warranties, of any kind related to this Website or
        the materials contained on this Website. Also, nothing contained on this
        Website shall be interpreted as advising you.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>7. Limitation of liability</strong></h2>
    <p>
      <span
        >In no event shall Employtec / 'wearelocals' &amp; 'wearebs3', nor any
        of its officers, directors and employees, shall be held liable for
        anything arising out of or in any way connected with your use of this
        Website whether such liability is under contract. Employtec /
        'wearelocals' &amp; 'wearebs3', including its officers, directors and
        employees shall not be held liable for any indirect, consequential or
        special liability arising out of or in any way related to your use of
        this Website.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>8. Indemnification</strong></h2>
    <p>
      <span
        >You hereby indemnify to the fullest extent Employtec / 'wearelocals'
        &amp; 'wearebs3' from and against any and/or all liabilities, costs,
        demands, causes of action, damages and expenses arising in any way
        related to your breach of any of the provisions of these Terms.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>9. Severability</strong></h2>
    <p>
      <span
        >If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>10. Variation of Terms</strong></h2>
    <p>
      <span
        >Employtec is permitted to revise these Terms at any time as it sees
        fit, and by using this Website you are expected to review these Terms on
        a regular basis.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>11. Assignment</strong></h2>
    <p>
      <span
        >'wearelocals' &amp; 'wearebs3' which is a trading name of Employtec is
        allowed to assign, transfer, and subcontract its rights and/or
        obligations under these Terms without any notification. However, you are
        not allowed to assign, transfer, or subcontract any of your rights
        and/or obligations under these Terms.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>12. Entire Agreement</strong></h2>
    <p>
      <span
        >These Terms constitute the entire agreement between 'wearelocals' &amp;
        'wearebs3' / Employtec and you in relation to your use of this Website,
        and supersede all prior agreements and understandings.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>13. Governing Law &amp; Jurisdiction</strong></h2>
    <p>
      <span
        >These Terms will be governed by and interpreted in accordance with the
        laws of The United Kingdom and you submit to the non-exclusive
        jurisdiction of the state and federal courts located in the UK for the
        resolution of any disputes.</span
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
