<template>
  <v-footer id="footer">
    <v-container class="content d-flex flex-column justify-space-between">
      <v-row>
        <v-col cols="12">
          <router-link to="/">
            <v-img
              max-width="180"
              to="/"
              class="mb-6"
              alt="We Are Locals logo"
              :src="require('@/assets/wearelocals_logo_small.png')"
            />
          </router-link>
        </v-col>
        <v-col cols="12" md="4">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/discover">Discover</router-link></li>
            <li>
              <a
                target="_blank"
                href="http://support.wearelocals.co.uk"
                to="/contact-us"
                >Support</a
              >
            </li>
            <li><router-link to="/contact-us">Contact us</router-link></li>
          </ul>
        </v-col>
        <v-col cols="12" md="4">
          <ul>
            <li>
              <a @click="open_legal_link('TermsAndConditions')">
                Terms and Conditions
              </a>
            </li>
            <li>
              <a @click="open_legal_link('PrivacyPolicy')">
                Privacy Policy
              </a>
            </li>
            <li>
              <a @click="open_legal_link('CookiesPolicy')">
                Cookies Policy
              </a>
            </li>
          </ul>
        </v-col>
        <router-link to=""></router-link>
      </v-row>
      <div class="flex-grow-0 mt-4 d-flex justify-space-between align-center">
        <span class="caption">
          &copy; WeAreLocals 2020
        </span>
        <span class="caption font-weight-bold">
          powered by
          <a href="https://employtec.com" target="_blank">Employtec</a></span
        >
      </div>
    </v-container>
    <v-dialog
      v-model="show_dialog"
      max-width="1200"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-btn class="mt-3" absolute right icon @click="close_legal_link()">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>

        <v-card-text class="scroll-y legal-content">
          <component
            v-if="current_legal_component"
            :is="current_legal_component"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import TermsAndConditions from "@/views/legal/TermsAndConditions";
import PrivacyPolicy from "@/views/legal/PrivacyPolicy";
import CookiesPolicy from "@/views/legal/CookiesPolicy";

export default {
  name: "Footer",
  components: {
    TermsAndConditions,
    PrivacyPolicy,
    CookiesPolicy,
  },
  data() {
    return {
      show_dialog: false,
      current_legal_component: null,
    };
  },
  methods: {
    open_legal_link(component_name) {
      this.current_legal_component = component_name;
      this.show_dialog = true;
    },
    close_legal_link() {
      this.current_legal_component = null;
      this.show_dialog = false;
      this.$emit("close_legal_page");
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  .content {
    height: 100%;
  }
  ul {
    li {
      list-style-type: none;
      a {
        color: #000000;
      }
    }
  }
}
</style>
