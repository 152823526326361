<template>
  <div class="legal">
    <h1 class="mb-5"><strong>Privacy Policy</strong></h1>
    <p>
      <strong
        >&lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; is a trading name of
        &lsquo;Employtec&rsquo;.</strong
      >
    </p>
    <p>
      <em
        ><span
          >Employtec (&lsquo;'wearelocals' &amp; 'wearebs3'&rsquo;
          &lsquo;we&rsquo; &lsquo;us&rsquo; &lsquo;our&rsquo;) is the data
          processor in relation to the data collected about you. The individual
          shop that you purchase from is the data controller. They will have
          their own privacy policy that is accessible through their individual
          shop front page.&nbsp; We care about your privacy and as we respect
          your privacy rights we endeavour to ensure that any personal data you
          give us via our Website remains private and is used only for the
          purposes outlined in this Privacy Policy.</span
        ></em
      >
    </p>
    <p>
      <em
        ><span
          >By using our Website you acknowledge the collection and use of your
          personal data as outlined below. This version of our Privacy Policy
          was last updated on 1/04/2020.</span
        ></em
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>1. About our privacy policy</strong></h2>
    <p>
      <span
        >Our website which includes any webpage containing this domain name,
        including any page that is enabled for use by a mobile device or any
        webpage redirected from another domain name (the &ldquo;Website&rdquo;)
        and the services provided on the Website (for a more complete
        description of the services see our Terms of Use).</span
      >
    </p>
    <p>
      <span
        >This Privacy Policy sets out the basis on which any personal data we
        collect from you, or that you provide to us, will be processed by us and
        your rights in relation to your personal data. Please read the following
        carefully to understand our views and practices regarding your personal
        data, how we will treat it and your data protection rights.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>2. Personal data we collect and process</strong></h2>
    <p>
      <span
        >When you place an order on the shop the following information is
        collected&nbsp;</span
      >
    </p>
    <p>&nbsp;</p>
    <ul>
      <li><span>Identity Data such as first name, surname,</span></li>
      <li>
        <span
          >Contact Data such as address, email address, telephone number.</span
        >
      </li>
      <li>
        <span
          >Technical Data such as internet protocol (IP) address, browser type
          and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform and other technology on the
          devices you use to access the Website.</span
        >
      </li>
      <li>
        <span
          >Usage Data such as information about how you use the
          Website&nbsp;</span
        >
      </li>
      <li>
        <span
          >Marketing and Communications Data such as your preferences in
          receiving marketing communications from us and third parties and your
          communication preferences.</span
        >
      </li>
    </ul>
    <p>&nbsp;</p>
    <h2><strong>4. How we collect personal data from and about you</strong></h2>
    <p>
      <span
        >Direct interactions: You may give us your Identity, Contact, Profile,
        Financial (Customers Only), Marketing and Communications Data by placing
        an order, filling in online forms or by communicating with us. The
        personal data we receive from direct interactions with you includes
        personal data you provide when you:</span
      >
    </p>
    <p>&nbsp;</p>
    <ul>
      <li><span>apply or subscribe for our Website;</span></li>
      <li>
        <span
          >create a user account on our Website and when you add to and manage
          your personal profile;</span
        >
      </li>
      <li>
        <span
          >contact or communicate with us and/or Customers via the Website or
          other services;</span
        >
      </li>
      <li><span>request marketing communications to be sent to you;</span></li>
    </ul>
    <p>
      <span
        >Automated technologies or interactions: As you interact with the
        Website, we collect Technical Data and Usage Data including details of
        your device, browsing actions and patterns, searches, products viewed,
        traffic data, web logs and other communication data and the resources
        that you access. We collect this personal data by using cookies,
        tracking codes, server logs and other similar technologies. We may also
        receive Technical Data about you if you visit other websites employing
        our cookies. Please see our cookies policy for further details.</span
      >
    </p>
    <p>
      <span
        >Third parties: We may receive personal data about you from various
        third parties as set out below:</span
      >
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        <span
          >Contact, Financial and Transaction Data from providers of technical,
          payment and delivery services such as Ably, Sendinblue,
          Stripe&nbsp;</span
        >
      </li>
      <li>
        <span
          >Contact and Profile Data from providers such as Survey Monkey</span
        >
      </li>
      <li><span>Analytics data from Google Analytics&nbsp;</span></li>
    </ul>
    <h2><strong>5. How we use your personal data</strong></h2>
    <p>
      <span
        >We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following
        circumstances:</span
      >
    </p>
    <ul>
      <li>
        <span
          >Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.</span
        >
      </li>
      <li>
        <span
          >Where we need to comply with a legal or regulatory obligation.</span
        >
      </li>
    </ul>
    <p>
      <span
        >Generally we do not rely on consent as a legal basis for processing
        your personal data. If we do rely on your consent to process your
        personal data this will be made clear to you at the time, and you have
        the right to decline to provide us with your consent and to withdraw
        your consent at any time by contacting us.</span
      >
    </p>
    <p>
      <span
        >We do not request from you and we request you do not provide any
        special categories/sensitive personal data (e.g. personal data relating
        to racial or ethnic origin, political or religious opinions, membership
        of a trade union, physical or mental health or condition or sexual life
        or orientation). This type of personal data is subject to special
        protections under EU law.</span
      >
    </p>
    <p>
      <span
        >The processing described in this section is necessary in order for us
        to perform our contract (e.g. Terms of Use) with you and is necessary
        for our legitimate interest to provide you with access to the shops on
        our platform and for you to purchase from them.&nbsp;</span
      >
    </p>
    <p>
      <span
        >We will collect and use Profile, Technical and Usage Data for data
        analytics purposes to improve the Website, products/services, marketing,
        user/Customer relationships and experience. This is necessary for our
        legitimate interest to ensure that Website content is presented in an
        effective manner for you and for your computer/device.</span
      >
    </p>
    <p>
      <span
        >We will collect and use Identity, Contact, Technical, Usage, Profile
        Data and Marketing and Communications Data to send you emails from
        &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; &amp; Employtec that you
        request from us or which we feel may interest you, but only where you
        have consented to be contacted for such purposes. This processing of
        your personal data is based on your consent and our legitimate interests
        to direct market, develop our products/services and grow our
        business</span
      >
    </p>
    <p>
      <span
        >We will collect and use Identity, Contact, Profile, Technical and Usage
        Data to allow you to participate in interactive features on the Website
        ('wearelocals' &amp; 'wearebs3') and one or more Employtec Services,
        when you choose to do so. This is necessary for us to perform our
        contract (e.g. Terms of Use) with you.</span
      >
    </p>
    <p>
      <span
        >We will collect and use Identity, Contact, Profile, Marketing and
        Communications Data to manage our relationship with you which will
        include notifying you about changes to our Website, service, terms
        and/or Privacy Policy. This is necessary for us (i) to perform our
        contract (e.g. Terms of Use) with you; and (ii) to comply with a legal
        obligation.</span
      >
    </p>
    <p>
      <span
        >We will collect and use Identity; Contact and Technical Data to
        administer and protect our business and the Website (including
        troubleshooting, data analysis, testing, system maintenance, support,
        reporting and hosting of data) which is necessary (i) for our legitimate
        interests (for running our business, provision of administration and IT
        services, network security, to prevent fraud and in the context of a
        business reorganization or group restructuring exercise) and (ii) to
        comply with a legal obligation;</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>6. Marketing</strong></h2>
    <p>
      <span
        >We may use your Identity, Contact, Technical, Usage and Profile Data to
        form a view on what we think you may want or need, or what may be of
        interest to you. This is how we decide which products, services and
        offers may be relevant for you.</span
      >
    </p>
    <p>
      <span
        >You will receive marketing communications from us if you have requested
        information from us or purchased services from us or if you provided us
        with your details when you entered a competition or registered for a
        promotion and, in each case, you have not opted out of receiving that
        marketing.</span
      >
    </p>
    <p>
      <span
        >You can opt out of any marketing communications from us at any time by
        using the &ldquo;Unsubscribe&rdquo; function at the bottom of the
        marketing email we send you.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>7. Disclosure of personal data to others</strong></h2>
    <p>
      <span
        >Except as set out in this Privacy Policy, we do not disclose to any
        third party personal data that we collect or you provide to us. We may
        disclose your personal data to third parties expressly mentioned in this
        Privacy Policy and the following third parties:</span
      >
    </p>
    <ul>
      <li>
        <span
          >if you have indicated at registration, or at any time during your
          time as a user of the Website, that you are happy to receive
          information or promotions from selected third parties about goods and
          services which may be of interest to you, we will send you such offers
          or information. See Marketing above on how to change your
          preferences;</span
        >
      </li>
      <li>
        <span
          >any member of our group of companies, which means our subsidiaries,
          our ultimate holding company and its subsidiaries, where it is
          necessary to do so for the provision and administration of the
          services we provide to you;</span
        >
      </li>
      <li>
        <span
          >We contract with other entities that perform certain tasks on our
          behalf and who are under our control (&ldquo;Service
          Providers&rdquo;). This is required in order to provide and manage the
          Website ('wearelocals' &amp; 'wearebs3') and one or more Employtec
          Service. Such Service Providers include IT systems suppliers and
          support, data storage, IT developers, insurance, credit card
          companies, payment processors, analytics companies, website hosting
          providers and other service providers;</span
        >
      </li>
      <li>
        <span
          >professional advisors such as accountants, auditors, lawyers,
          bankers, insurers, and other outside professional advisors;</span
        >
      </li>
      <li>
        <span
          >third parties to whom we may choose to sell, transfer, or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this Privacy Policy;</span
        >
      </li>
      <li>
        <span
          >entities that regulate or have jurisdiction over us. We will disclose
          your personal data in order to comply with any legal obligation, if we
          are ordered to do so by a court of competent jurisdiction, law
          enforcement, regulatory or administrative authorities or in order to
          enforce or apply our Terms and Conditions and other agreements, or to
          protect the rights, property, or safety of Employtec, our Customers,
          Website users or others. This includes exchanging personal data with
          third parties for the purposes of fraud protection and credit risk
          reduction.</span
        >
      </li>
    </ul>
    <p>
      <span
        >We require all third parties to respect the security of your personal
        data and to treat it in accordance with the law. We do not allow our
        Service Providers to use your personal data for their own purposes and
        only permit them to process your personal data for specified purposes
        and in accordance with our instructions. Unless prevented by applicable
        law, we will notify you when your personal data may be provided to third
        parties in ways other than explained above, and you may have the option
        to prevent such sharing at the time that we notify you.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>8. Customer control of your personal data</strong></h2>
    <p>
      <span
        >If you purchase or communicate with Shops&nbsp; via the Website the
        relevant Customer will control the personal data you disclose. This will
        typically relate to your Identity, Contact and Profile Data. Both
        Employtec and the Customer will jointly control this personal data, and
        we have arrangements in place intended to ensure your personal data is
        only used by Customers in accordance with this Privacy Policy. Please
        exercise your data protection rights relating to this jointly controlled
        personal data by contacting us at gdpr@employtec.com</span
      >
    </p>
    <p>
      <span
        >If a Customer to whom you or we have disclosed any of your personal
        data downloads or copies the personal data to a medium other than our
        Website, Employtec will no longer jointly control this personal data.
        Such data will be subject to the Customer's own data protection and
        privacy practices. If you wish to understand the Customer's data
        protection and privacy practices please contact the Customer directly.
        You will be able to exercise your Data Protection Rights directly with
        the Customer.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>9. Data security</strong></h2>
    <p>
      <span
        >Employtec &amp; &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; is
        committed to protecting the personal data you provide us. To prevent
        unauthorized access or disclosure of personal data under our control,
        Employtec has appropriate security management systems in place to
        safeguard the personal data we collect. Firewalls, intrusion detection
        and virus scanners are used on all parts of the Website. Encryption
        during transmission is also used on sections of the Website where
        security is particularly important and on all data in transit on the TMS
        system. We also enforce HTTPS.</span
      >
    </p>
    <p>
      <span
        >While Employtec makes reasonable efforts to protect all personal data,
        no data transmission over the Internet can be guaranteed to be 100%
        secure. As a result Employtec cannot ensure or warrant the security of
        any personal data you transmit to us, you do so at your own risk. You
        acknowledge that we are not responsible for any intercepted information
        sent via the Internet, and you hereby waive, release and discharge us
        from any and all claims arising out of or related to the use of
        intercepted information in any unauthorized manner.</span
      >
    </p>
    <p>
      <span
        >Where we have given you (or where you have chosen) a password which
        enables you to access certain parts of the Website, you are responsible
        for keeping this password confidential. You must not share your Website
        password with anyone.</span
      >
    </p>
    <p>
      <span
        >When you disclose personal data, or request us to make your personal
        data available, to Customers on the Website that personal data will be
        used by the Customer for its own purposes (e.g. to contact you, arrange
        an interview, review your suitability for a particular position) and
        such third party purposes and activities are beyond our control.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>10. Updating your personal data</strong></h2>
    <p>
      <span
        >It is important that the personal data we hold is accurate and current.
        Please keep us informed if any of your personal data changes during your
        relationship with us. It is your responsibility to keep your personal
        data up to date at all times.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>11. Links to third party websites</strong></h2>
    <p>
      <span
        >Employtec / 'wearelocals' &amp; 'wearebs3' product &amp; services
        advertising clients are given the option to have a profile page on the
        Website. This includes information related to their company such as:
        company logo, contact details, company profile, web address and related
        links. This information is disclosed for your convenience and
        information, however if you access some of the links and web addresses
        disclosed on these pages, you will leave our Website.</span
      >
    </p>
    <p>
      <span
        >The Website contains links to and from the websites of our partner
        networks, Customers, advertisers and affiliates as well as third party
        websites to which we have no affiliation. If you follow a link to any of
        these websites, please note that these websites have their own privacy
        policies and that we do not accept any responsibility or liability for
        these websites or their policies.</span
      >
    </p>
    <p>
      <span
        >We also use third party advertisements to support the Website. Some of
        these advertisers may use technology such as cookies and web beacons
        when they advertise on the Website, which will also send these
        advertisers (such as Google through the Google AdSense program)
        information including your IP address, your ISP, the browser you used to
        visit the Website, and in some cases, whether you have Flash installed.
        This is generally used for geotargeting purposes or showing certain ads
        based on specific sites visited.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>12. IP addresses and cookies</strong></h2>
    <p>
      <span
        >We may collect information about your computer, including (where
        available) your IP address, operating system and browser type, for
        system administration and statistical purposes.</span
      >
    </p>
    <p>
      <span
        >For the same reason, we may obtain information about your general
        internet usage by using a cookie file which is stored on the hard drive
        of your computer. Cookies contain information that is transferred to
        your computer's hard drive. On revisiting the Website our computer
        server will recognize the cookie, giving us information about your last
        visit. They help us to improve the Website and to deliver a better and
        more personalised service. They enable us:</span
      >
    </p>
    <p>&nbsp;</p>
    <ul>
      <li><span>to estimate our audience size and usage pattern;</span></li>
      <li>
        <span
          >to store information about your preferences, and so allow us to
          customize the Website according to your individual interests and make
          your usage of the Website more enjoyable;and</span
        >
      </li>
      <li><span>to speed up your searches.</span></li>
    </ul>
    <p>
      <span
        >You may refuse to accept cookies by activating the setting on your
        browser which allows you to refuse the setting of cookies. However, if
        you select this setting you may be unable to access certain parts of the
        Website. Unless you have adjusted your browser setting so that it will
        refuse cookies, our system will issue cookies when you log on to the
        Website.</span
      >
    </p>
    <p>
      <span
        >For more information about how our cookies work view our cookies
        policy.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>13. Data retention</strong></h2>
    <p>
      <span
        >We retain personal data for no longer than is allowed under data
        protection law and, in any case, no longer than such personal data is
        necessary for the purpose for which it was processed. To determine the
        appropriate retention period for personal data, we consider the amount,
        nature, and sensitivity of the personal data, the potential risk of harm
        from unauthorized use or disclosure, the purposes for which we process
        personal data and whether we can achieve those purposes through other
        means, and the applicable legal requirements.</span
      >
    </p>
    <p>
      <span
        >As a registered user of the Website we will retain your Identity,
        Contact, Profile, Technical, Usage, Marketing and Communications Data
        for as long as your user account is live. We retain the personal data
        you provide while your account is in existence or as needed to provide
        you access to the Website and any Employtec Service you have used. Even
        if you only use the Website or any other Employtec Service occasionally,
        we will retain your Identity, Contact, Profile, Technical, Usage,
        Marketing and Communications Data until you decide to close your user
        account. In some cases we retain certain Usage Data (e.g. visitor and
        application statistics in a depersonalized or aggregated form). Also, we
        retain personal data for longer if required by applicable law or
        regulation or justified under applicable statutory limitation
        periods.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>14. Your legal rights</strong></h2>
    <p>
      <span
        >Under certain circumstances you have rights under applicable data
        protection law in relation to personal data, namely:</span
      >
    </p>
    <p>
      <span
        >Request access to your personal data (commonly known as a &ldquo;data
        subject access request&rdquo;). This enables you to receive a copy of
        the personal data we hold about you and to check that we are lawfully
        processing it.</span
      >
    </p>
    <p>
      <span
        >Request correction of the personal data that we hold about you. This
        enables you to have any incomplete or inaccurate data we hold about you
        corrected, though we may need to verify the accuracy of the new data you
        provide to us.</span
      >
    </p>
    <p>
      <span
        >Request erasure of your personal data. This enables you to ask us to
        delete or remove personal data where there is no good reason for us
        continuing to process it. You also have the right to ask us to delete or
        remove your personal data where you have successfully exercised your
        right to object to processing (see below), where we may have processed
        your information unlawfully or where we are required to erase your
        personal data to comply with local law. Note, however, that we may not
        always be able to comply with your request of erasure for specific legal
        reasons which will be notified to you, if applicable, at the time of
        your request.</span
      >
    </p>
    <p>
      <span
        >Object to processing of your personal data where we are relying on a
        legitimate interest (or those of a third party) and there is something
        about your particular situation which makes you want to object to
        processing on this ground as you feel it impacts on your fundamental
        rights and freedoms. You also have the right to object where we are
        processing your personal data for direct marketing purposes. In some
        cases, we may demonstrate that we have compelling legitimate grounds to
        process your information which override your rights and freedoms.</span
      >
    </p>
    <p>
      <span
        >Request restriction of processing of your personal data. This enables
        you to ask us to suspend the processing of your personal data in the
        following scenarios: (a) if you want us to establish the data's
        accuracy; (b) where our use of the data is unlawful but you do not want
        us to erase it; (c) where you need us to hold the data even if we no
        longer require it as you need it to establish, exercise or defend legal
        claims; or (d) you have objected to our use of your data but we need to
        verify whether we have overriding legitimate grounds to use it.</span
      >
    </p>
    <p>
      <span
        >Request the transfer of your personal data to you or to a third party.
        We will provide to you, or a third party you have chosen, your personal
        data in a structured, commonly used, machine-readable format. Note that
        this right only applies to automated information which you initially
        provided consent for us to use or where we used the information to
        perform a contract with you.</span
      >
    </p>
    <p>
      <span
        >Withdraw consent at any time if and to the extent we are relying on
        consent as the legal basis to process your personal data. However, this
        will not affect the lawfulness of any processing carried out before you
        withdraw your consent. If you withdraw your consent, we may not be able
        to provide certain products or services to you. We will advise you if
        this is the case at the time you withdraw your consent.</span
      >
    </p>
    <p>
      <span
        >Regarding your right to have your personal data erased, you can delete
        your user account with Employtec / 'wearelocals' &amp; 'wearebs3' at any
        time. Once your user account is deleted, it is gone forever and cannot
        be restored. Some personal data however may still be stored and visible
        in other places on the Website. For example, if you made an order online
        with a third party shop, information including your name, email and
        contact number may still be visible to that business in their user
        account on the Website. We will endeavour to contact businesses we know
        who have accessed your personal data within the previous 18 months and
        inform them of your request to erase your personal data. You can also
        contact them directly.&nbsp;</span
      >
    </p>
    <p>
      <span
        >You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights). However, we may charge a reasonable
        fee if your request is clearly unfounded, repetitive or excessive.
        Alternatively, we may refuse to comply with your request in these
        circumstances. We may need to request specific information from you to
        help us confirm your identity and ensure your right to access your
        personal data (or to exercise any of your other rights). This is a
        security measure to ensure that personal data is not disclosed to any
        person who has no right to receive it. We may also contact you to ask
        you for further information in relation to your request to speed up our
        response.</span
      >
    </p>
    <p>
      <span
        >In order to exercise one or more of your rights in respect of your
        personal data please contact us at gdpr@employtec.com. We will respond
        to your request(s) as soon as reasonably practicable, but in any case
        within the legally required period of time</span
      >
    </p>
    <p>
      <span
        >You have the right to make a complaint at any time to the Information
        Commissioner&rsquo;s Office, the UK supervisory authority for data
        protection issues at </span
      ><a href="https://ico.org.uk/make-a-complaint/"><span>ico.org.uk</span></a
      ><span>
        We would, however, appreciate the chance to deal with your concerns
        before you approach the Information Commissioner&rsquo;s Office please
        contact us in the first instance at gdpr@employtec.com.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>15. Changes to our privacy policy</strong></h2>
    <p>
      <span
        >Any changes we may make to our Privacy Policy in the future will be
        posted on this page, you may also be notified by email. You should visit
        this page periodically to review any changes to the Privacy
        Policy.</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><strong>Contact us</strong></h2>
    <p>
      <span
        >If you have any questions about this Privacy Policy, including any
        requests to exercise your legal rights, please contact us at
        gdpr@employtec.com</span
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
